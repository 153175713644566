export interface EntityAccount {
    account_id: string,
    name: string,
    structures: string[],
    timestamp: Date,
    web_idle_session_timeout_seconds?: number
}

export class Account {
    static create (userData: EntityAccount): Account {
        const {
            account_id: accountId,
            name,
            structures,
            timestamp: timestampString,
            web_idle_session_timeout_seconds: webIdleSessionTimeoutSecondsRaw
        } = userData;
        
        const timestamp = new Date(timestampString);
        const webIdleSessionTimeoutSeconds = webIdleSessionTimeoutSecondsRaw ? webIdleSessionTimeoutSecondsRaw : -1;

        return new Account(
            accountId,
            name,
            structures,
            timestamp,
            webIdleSessionTimeoutSeconds
        );
    }

    constructor (
        public readonly accountId: string,
        public readonly name: string,
        public readonly structures: string[],
        public readonly timestamp: Date,
        public readonly webIdleSessionTimeoutSeconds: number
    ) {
    }
}
